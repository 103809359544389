import React from 'react';
import { LoadingRenderComponent } from './common/LoadingRenderComponent';

import type { SmartCommsTypes } from '../types/SmartCommsApi';

// currently need to have https://eu4.smartcommunications.cloud/one/project-dashboard/ opened

export const DocumentEditor: React.FC = () => {
  const [editor, setEditor] = React.useState<SmartCommsTypes['DraftEditorUsableV3'] | null>(null);
  const [editorIsLoading, setEditorIsLoading] = React.useState(true);
  const inputFile = React.useRef<HTMLInputElement | null>(null);

  const loadSmartDxDraftEditorLibrary = (
    onLibraryLoaded: () => void,
    onLibraryLoadingFailed: () => void,
  ) => {
    const existingScript = document.getElementById('smartDxDraftEditor');
    if (!existingScript) {
      const script = document.createElement('script');

      // TODO TCN: quand l'appliance sera setupée, on pourra charger ça depuis une URL à nous (.fr.world.socgen), et éviter d'avoir à s'authentifier
      // sur la tenancy.
      script.src =
        'https://eu4.smartcommunications.cloud/one/draft-editor/js/draft-editor-api-min.js';
      script.id = 'smartDxDraftEditor';

      script.onload = () => {
        onLibraryLoaded?.();
      };
      script.onabort = () => {
        onLibraryLoadingFailed?.();
      };
      script.onerror = () => {
        onLibraryLoadingFailed?.();
      };

      document.body.appendChild(script);
    }
    if (existingScript) {
      onLibraryLoaded?.();
    }
  };

  // Client Id : 56decd15-382d-4cd3-a55c-e6f9c7baf0f4
  // Client Secret: 77d4a883-7d85-4086-ba8c-a41cd75131d8

  React.useEffect(() => {
    setEditorIsLoading(false); // <= surtout utile quand on est en DEV pour avoir un display cohérent (le flag n'est autrement pas reset après le CTRL+S).

    loadSmartDxDraftEditorLibrary(
      async () => {
        // La documentation complète du draft editor est ici: https://eu4.smartcommunications.cloud/one/draft-editor/api-jsdoc/SmartComms.DraftEditor.html

        //eu4.smartcommunications.cloud/one/saml/login/8b9e1f18-9444-4f71-952e-1aabe6089d03?targetURL=%2Foauth2Server%2Fauthorize%3Fresponse_type%3Dcode%26client_id=56decd15-382d-4cd3-a55c-e6f9c7baf0f4&redirect_uri=https%3A%2F%2Feu4.smartcommunications.cloud/one

        const smart: SmartCommsTypes = (global as any).SmartComms;
        smart.DraftEditor.prototype.DRAFTEDITOR_CONTEXT = '/one/draft-editor';

        const newEditor = await smart.DraftEditor.create({
          server: 'https://eu4.smartcommunications.cloud',
          clientServer: (location.protocol +
            '//' +
            location.host) as SmartCommsTypes['DraftEditorProperties']['clientServer'],
          targetElementID: 'draftEditorPlaceHolder',
          authUrl:
            'https://eu4.smartcommunications.cloud/one/saml/login/8b9e1f18-9444-4f71-952e-1aabe6089d03',
          authParams:
            'samlIdpLoginPopup=true&targetURL=https://eu4.smartcommunications.cloud/one/draft-editor/draft-editor.jsp?clientServer=' +
            encodeURI(location.protocol + '//' + location.host),
          loadStartupConfig: () => ({
            editingMode: 'defaultMode',
          }),
        });

        try {
          await newEditor.v3.setTheme('dark');
        } catch (e) {
          console.log(e);
        }

        setEditor(newEditor.v3);
        setEditorIsLoading(false);

        /*try {
        const res = await newEditor.v3.compareDraftTML('<p>test</p>', '<p>test</p>');
        console.log({res});
      } catch (e) {
        console.log(e)
      }

      try {
        const res = await newEditor.v3.compareDraftTML('<p>test</p>', '<p>foobar</p>');
        console.log({res});
      } catch (e) {
        console.log(e)
      }*/
      },
      () => {
        console.error('loading script failed');
        setEditorIsLoading(false);
      },
    );
  }, [setEditorIsLoading]);

  const openFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const openFileChoosen = () => {
    if (editor && inputFile.current && inputFile.current.files) {
      const reader = new FileReader();

      reader.onload = async e => {
        const text = e.target?.result as string;
        try {
          await editor.loadString(text, {
            openInPreview: true,
          });
        } catch (e) {
          console.log(e);
        }
      };

      reader.readAsText(inputFile.current.files[0]);
    }
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="row pt-5">
          <div className="col">
            <LoadingRenderComponent isLoading={editorIsLoading}>
              <div className="d-flex justify-content-start">
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={openFileChoosen}
                />
                <button className="btn btn-md btn-primary btn-icon-text mr-1" onClick={openFile}>
                  <i className="icon">open_in_browser</i>Open file...
                </button>
                <button className="btn btn-md btn-primary btn-icon-text mr-1">
                  <i className="icon">open_in_browser</i>Open deal...
                </button>
                <button className="btn btn-md btn-success btn-icon-text mr-1">
                  <i className="icon">save</i>Save
                </button>
                <button className="btn btn-md btn-socgen btn-icon-text mr-1">
                  <i className="icon">forward_to_inbox</i>Save and Send
                </button>
                <button className="btn btn-md btn-info btn-icon-text">
                  <i className="icon">move_to_inbox</i>Save and Store
                </button>
              </div>
            </LoadingRenderComponent>
            <div
              id="draftEditorPlaceHolder"
              style={{ height: '70vh', display: `${!editorIsLoading ? 'block' : 'none'}` }}
            ></div>
            <div className="d-flex justify-content-end">
              <button className="btn btn-md btn-flat-secondary btn-icon-text">
                <i className="icon">description</i>Export as XML...
              </button>
              <button className="btn btn-md btn-flat-secondary btn-icon-text">
                <i className="icon">picture_as_pdf</i>Export as PDF...
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
