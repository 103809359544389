import React, { useEffect, useMemo } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Sgb4Spinner } from './Sgb4Spinner';
import { PresentationService } from '../../api/PresentationService';
import { ToasterContext } from '../../context/ToasterContext';
import { UserContext } from '../../context/UserContext';
import { LoadingRenderComponent } from './LoadingRenderComponent';

import type { XOneClientScope, StaticDataSearchResults } from '../../types/presentation'


export const ClientScopeSelector: React.FunctionComponent = () => {
  const { globalContext } = React.useContext(
    UserContext,
  );
  const foo = Object.entries((globalContext.permissions as any)?.permissions.clientScopes ?? {});
  if (foo.length <= 1) {
    return <></>;
  }

  const { pushToastMessage } = React.useContext(ToasterContext);
  const [
    showClientScopesDropDown,
    setShowClientScopesDropDown,
  ] = React.useState(false);
  const [clientScopes, setClientScopes] = React.useState<XOneClientScope[]>([]);
  const abortController = new AbortController();

  useEffect(() => {
    if (clientScopes.length === 0) {
      PresentationService.getClientScopes(
        abortController.signal,
        globalContext,
      )
        .then((data) => {
          setClientScopes(data.clientScopes);
        })
        .catch((error) => {
          console.error('Cannot get your client-scopes', error);
          pushToastMessage({
            body: { id: 'header.error-fetching-client-scopes' },
            level: 'danger',
            title: { id: 'label.danger' },
          });
        });
    }
  }, []);
  const toggleShowClientScopes = () => {
    setShowClientScopesDropDown(!showClientScopesDropDown);
  };

  const chooseClientScope = (chosenClientScopeCode: string) => {
    PresentationService.setClientScope(
      chosenClientScopeCode,
      abortController.signal,
      globalContext,
    )
      .then(() => {
        console.info('Client-scope changed!', chosenClientScopeCode);
        globalContext.setClientScope(chosenClientScopeCode);
      })
      .catch((error) => {
        console.error('Cannot set your client-scopes', error);
        pushToastMessage({
          body: { id: 'header.error-setting-client-scopes' },
          level: 'danger',
          title: { id: 'label.danger' },
        });
      });
  };

  const currentClientScopeLabel = useMemo(
    () =>
      clientScopes.find((clientScope) => clientScope.code === globalContext.clientScopeCode)
        ?.label ?? '-',
    [clientScopes, globalContext.clientScopeCode],
  );

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={showClientScopesDropDown}
      toggle={toggleShowClientScopes}
      style={{ listStyleType: 'none' }}
    >
      <DropdownToggle nav caret={false}>
        Scope: {currentClientScopeLabel}
      </DropdownToggle>
      <DropdownMenu left="true">
        <LoadingRenderComponent
          isLoading={clientScopes.length === 0}
          loadingComponent={
            <DropdownItem>
              <Sgb4Spinner size="xs" />
              Loading your client-scopes...
            </DropdownItem>
          }
        >
          {clientScopes.map((clientScope) => (
            <React.Fragment key={clientScope.code}>
              <DropdownItem
                className={globalContext.clientScopeCode === clientScope.code ? 'active' : ''}
                onClick={() => chooseClientScope(clientScope.code)}
              >
                {clientScope.label}
              </DropdownItem>
              <DropdownItem divider />
            </React.Fragment>
          ))}
        </LoadingRenderComponent>
      </DropdownMenu>
    </Dropdown>
  );
};
