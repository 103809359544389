import { AccountCenter } from '@/components/sgwt/AccountCenter';
import { ClientScopeSelector } from '../common/ClientScopeSelector'

export function Header() {
  return (
    <header className="navbar navbar-expand d-flex justify-content-between align-content-center border-bottom">
      <a className="navbar-brand" href="/">
        <img src="/favicon.svg" alt="SG" className="border border-white" />
      </a>

      <ClientScopeSelector />

      <AccountCenter />
    </header>
  );
}