import React from 'react';
import type { GlobalContext } from '@sgmo/shared';

interface UserContextProps {
  globalContext: GlobalContext;
}

export const UserContext = React.createContext<UserContextProps>({
  globalContext: {
    clientScopeCode: '',
    setClientScope: () => undefined,
    permissions: null,
    sgConnect: null,
    appConfig: null
  }
});
