import React from 'react';

import type { ToastMessage } from '../types/toast'

interface ToasterProps {
  pushToastMessage: (message: ToastMessage) => void
}

export const ToasterContext = React.createContext<ToasterProps>({
  pushToastMessage: () => { }
});