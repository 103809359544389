import './App.css';
import { getUserConnection } from '@/widgets/widgets';
import { Footer } from '@/components/layout/Footer';
import { Header } from '@/components/layout/Header';
import { IntlProvider } from '@/context/IntlContext';
import { FormattedMessage } from 'react-intl';

import { DocumentEditor } from './DocumentEditor'

import { UserContext } from '../context/UserContext';
import { ToasterProvider } from './common/ToasterProvider';

import type { SGWTConnectCore } from '@sgwt/connect-core';

import { getConfig } from '../config/config'

export function App({sgConnect}: {sgConnect: SGWTConnectCore}) {

  const globalContext = {
    clientScopeCode: '',
    setClientScope: () => {},
    permissions: null,
    sgConnect: sgConnect,
    appConfig: getConfig() as any
  }

  return (
    <IntlProvider>
      <UserContext.Provider
        value={{ globalContext: globalContext }}
      >
        <div className="app">
          <Header />

          <main>
            <DocumentEditor/>
  {/*          <FormattedMessage id={'App.Welcome'} values={{ user: getUserConnection()?.mail }} />*/}
          </main>

          <Footer />
        </div>
      </UserContext.Provider>
    </IntlProvider>
  );
}
