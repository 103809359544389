import { render } from 'react-dom';
import { StrictMode } from 'react';

import { App } from './components/App';
import { SgwtConnectHTMLElement } from './types/sgwt-widgets';
import { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';
import { sgwtConnect } from '@/widgets/sgwtConnect';

const sgwtConnectCore = setupSgwtConnectWidget(sgwtConnect);

if (sgwtConnect.isAuthorized()) {
  renderApp();
} else {
  const authorizationError = sgwtConnect.getAuthorizationError();
  if (authorizationError !== null) {
    renderError(authorizationError);
  } else {
    sgwtConnect.requestAuthorization();
  }
}

function renderApp() {
  render(
    <StrictMode>
      <App sgConnect={sgwtConnect} />
    </StrictMode>,
    document.getElementById('root'),
  );
}

function renderError(authorizationError: SGWTConnectError) {
  document.body.innerHTML = `
      <div class='alert alert-danger' role='alert'>
        Authorization error: ${authorizationError}.
      </div>`;
}

function setupSgwtConnectWidget(sgwtConnect: SGWTConnectCore) {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
  if (widget) {
    // When the code is executed, the swgtConnectWidget may not have been initialized. So, we need to check that, otherwise calling
    // `swgtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the swgtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}
